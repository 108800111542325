/*eslint-disable */
var clearTimeoutState;

import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import addAboutOfUserInfo from '@/components/authentication/add-about-of-user-info/';
import allCountries from '@/services/phoneCodeList.json';
import alreadyUserRegisteredPopup from '@/components/authentication/already-registered-user-popup/';
import {bus} from '@/main';
import toast from '@/services/toast';

Vue.use(VueTelInput)
export default {
  name: 'signup',
  components: {
    'add-about-user-info' : addAboutOfUserInfo,
    'already-user-registered':alreadyUserRegisteredPopup
  },
  props: [],
  data () {
    return {
      first_name:'',
      last_name:'',
      email:'',
      password:'',
      confirm_password:'',
      submitted:false,
      phone: {
        number: '',
        isValid: false,
        country: '',
        countryIso: ''
      },
      allCountriesList: [],
      open:false,
      accept_terms:'',
      phoneNumber:{
        fullOTP:''
      },
      signUpFormMain:true,
      signUpFormVerify:false,
      signUpFormVerifyPopup:false,
      gender:'',
      dob:'',
      addAboutPopupState:false,
      blurTextbPopupState:false,
      signup_type:'patient',
      countDown: 30,
      resentOtpFlag:false,
      phoneNumberBlurState:false,
      minDate:new Date(2016, 0, 0),
      alreadyUsrRegPopState:false,
      alreadyUsrRegData:null
    }
  },
  computed: {

  },
  mounted () {
    // loop over all of the countries above, restructuring the data to be objects with named keys
    for (var i = 0; i < allCountries.length; i++) {
      let c = allCountries[i];
      this.allCountriesList.push({
        name: c[0],
        iso2: c[1],
        dialCode: c[2],
        priority: c[3] || 0,
        areaCodes: c[4] || null
      });
    }
    this.getUserCountry();
    bus.$on('addAboutPopupStateBus', (data) => {
      this.addAboutPopupState = data
    });
    bus.$on('blurTextbPopupStateBus', (data) => {
      this.blurTextbPopupState = data
    });
    bus.$on('signUpFormMainBus', (data) => {
      this.signUpFormMain = data
    });
    bus.$on('signUpFormVerifyBus', (data) => {
      this.signUpFormVerify = data
    });
    bus.$on('verfiyMobileInfoBus', (data) => {
      this.phone.number = data.number
      this.phone.country = data.country
      this.signup_type = data.signup_type
    });
    bus.$on('alreadyUsrRegPopStateBus', (data) => {
      this.alreadyUsrRegPopState = data
      this.alreadyUsrRegData = null
    });
  },
  methods: {
    phoneNumberFocusMethod(){
      this.phoneNumberBlurState = true
    },
    phoneNumberBlurMethod(){
      this.phoneNumberBlurState = false
    },
    countDownTimer() {
      if(this.countDown > 0) {
        clearTimeoutState = setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
    },
    loginPopupShow(){
      bus.$emit('signupPopupStateBus', false)
      bus.$emit('loginPopupStateBus', true)
    },
    openAddAboutPopup(){
      window.scrollTo(0, 0)
      document.body.classList.add('popup_open_body')
      this.blurTextbPopupState =  true
    },
    signupPopupClosed(){
      bus.$emit('signupPopupStateBus', false)
      bus.$emit('landingMainContentBus', true)
    },
    signupPopupClosedOnLogo(){
      bus.$emit('signupPopupStateBus', false)
      bus.$emit('landingMainContentBus', true)
    },
    clickedOutside() {
      this.open = false;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    getUserCountry() {
      var self = this;
      fetch('https://ip2c.org/s')
        .then(function(response) {
          return response.text();
        })
        .then(function(text) {
          if (!text || "1" !== text[0])
            throw new Error("unable to fetch the country");
          if (self.phone.country == '') {
            self.countryName = (text.substr(2, 2)).toLowerCase();
            self.phone.countryIso = (text.substr(2, 2)).toLowerCase();
            self.phone.country = self.allCountriesList.filter(item => item.iso2 == self.phone.countryIso)[0].dialCode;
          }
        })
        .catch(function(error) {
          console.log('Request failed', error)
        });
    },
    chooseCountry(country) {
      this.phone.countryIso = country.iso2;
      this.phone.country = country.dialCode;
    },
    validateSignBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let SingUpData = {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            mobile_country_code:'+' + this.phone.country,
            mobile:this.phone.number,
            gender:this.gender,
            dob:this.dob,
            signup_type:'patient',
          }
          this.$store.dispatch('authUserSignupApiFun', SingUpData).then((res) => {
            let optSendData = {
              email: this.email,
              mobile_country_code:'+' + this.phone.country,
              mobile:this.phone.number
            }
            this.$store.dispatch('optCreateOnMobileApiFun', optSendData).then((res) => {
              toast.success(res.data.user_msg || res.data.msg);
              this.signUpFormMain = false
              this.signUpFormVerify = true
              this.submitted =  false
              this.countDownTimer()
            })
          }).catch((err) => {
            // this.alreadyUsrRegData = err.data.data.use;
            toast.error(err.data.user_msg);
            this.submitted =  false
            if(err.data.code == '4001'){
              this.alreadyUsrRegData = err.data.data.user_type; 
              this.alreadyUsrRegPopState = true;    
              document.body.classList.add('popup_open_body');      
            }
        })
        return;
        }
      })
    },
    resendOtpMethod() {
      this.$store.dispatch('checkLoginType', {
          email: this.email,
          mobile: this.phone.number
        })
        .then((res) => {
          toast.success(res.data.user_msg);
          this.countDown = 30
          this.countDownTimer();
          this.resentOtpFlag = true
        }).catch((err) => {
          toast.error(err.data.data.non_field_errors || err.data.msg);
        })
    },
    validateSignVerifyBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          let data = {
            mobile_country_code:'+' + this.phone.country,
            mobile:this.phone.number,
            otp:this.phoneNumber.fullOTP,
            signup_type:this.signup_type,
          }
          this.$store.dispatch('signUpVerifyAction', data)
            .then((res) => {  
              document.getElementById('preloader').style.display = "flex";
              toast.success(res.data.msg);
              this.submitted = false;
              this.$router.push('/dashboard/');
            }).catch((err) => {
              toast.error(err.data.user_msg);
              this.submitted =  false
            })
          return;
        }
      })
    },
  }
}
/*eslint-disable */

import countries from '@/services/country.json'

export default {
  name: 'public-ngo-list',
  components: {},
  props: [],
  data () {
    return {
      rightToLeftAnimation:false,
      ngoListAnimationState:false,
      ngoListAnimationShow:false,
      slideLeftArrowShowState:false,
      publicNgoList:null,
      publicNgoListResults:[],
      countryList:[],
      pubNgoListPopupState:true
    }
  },
  computed: {

  },
  mounted () {
    this.$store.dispatch('getAllPaginatedPublicNgoListFunc').then((res) => {
      this.publicNgoList = res.data,
      this.publicNgoListResults = this.publicNgoList.results
      if(this.publicNgoListResults.length > 0){
        setTimeout(() => {
          this.rightToLeftAnimation = true
        }, 500);
        setTimeout(() => {
          this.ngoListAnimationShow = true
        }, 2400);
        setTimeout(() => {
          this.ngoListAnimationState = true
        }, 2500);
        setTimeout(() => {
          this.slideLeftArrowShowState = true
        }, 4200);
      }
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    routerPushInPublicNgoMethod(){
      this.$router.push('/public/ngo/list/');
    },
    getCountryName(countryCode) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
    pubNgoListPopupOpenMethod(){
      if(this.pubNgoListPopupState){
        this.pubNgoListPopupState = false
      }else{
        this.pubNgoListPopupState = true
      }
    }
  },
}
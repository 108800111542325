<template>
    <div class="blurb_text_popup">
        <div class="modal-backdrop"></div>
        <div class="modal modal-open unsupported-browser">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <template v-if="signUpData == 'patient'">
                            <p class="para_heading">Your credentials have already been registered.</p>
                            <p>Please sign up on LBIL with another email address and active mobile number. As per our User
                                Agreement and Privacy Policy, we have a one-account-per-user policy. Therefore, we kindly
                                request all users create a separate LBIL account (<a href="https://lbil.ai/"
                                    target="_blank">www.lbil.ai</a>) using a different email address and mobile number.</p>
                            <p>Please note that duplicate accounts will be deactivated and deleted from the system. You may
                                learn more by clicking on our <router-link :to="{name:'privacyPolicy'}">privacy policy</router-link>.</p>
                            <p>If you have any questions, you may contact us at <a href="mailto:admin@lbil.ai">admin@lbil.ai</a>.</p>
                            <h3 class="mb-3">Thank you for choosing LBIL.</h3>
                            <button class="btn btn-primary" @click="blurbTextPopupClosed">Ok</button>
                        </template>
                        <template v-else>
                            <p>Your credentials have already been registered on our teleconsulting portal (<a href="www.consult.lbil.ai" target="_blank">www.consult.lbil.ai</a>).</p>
                            <p>Please sign up on LBIL's public site with another email address and active mobile number. As per our User Agreement and Privacy Policy, we have a one-account-per-user policy. Therefore, we kindly request all professionals to create a separate account on LBIL (<a href="https://lbil.ai/" target="_blank">www.lbil.ai</a>) using a different email address and mobile number.</p>
                            <p>If you have any questions, you may contact us at <a href="mailto:admin@lbil.ai">admin@lbil.ai</a>.</p>
                            <h3 class="mb-3">Thank you for choosing LBIL.</h3>
                            <button class="btn btn-primary" @click="blurbTextPopupClosed">Ok</button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import { bus } from '@/main';
export default {
    name: 'blurb-content-for-lymphomap-signup',
    components: {},
    props: ['signUpData'],
    data() {
        return {
        }
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        blurbTextPopupClosed() {
            bus.$emit('alreadyUsrRegPopStateBus', false);
            document.body.classList.remove('popup_open_body')
        }
    }
}
</script>

<style lang="scss" scoped>
.blurb_text_popup {
    a:hover {
        text-decoration: none;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1040;
        background-color: #000;
        opacity: .92;
    }

    .unsupported-browser {
        &.modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1050;
            overflow: hidden;
            outline: 0;

            &.modal-open {
                overflow-x: hidden;
                overflow-y: auto;
                display: block;
            }

            .modal-dialog {
                position: relative;
                width: auto;
                margin: .5rem;
                pointer-events: none;

                @media (min-width: 730px) {
                    max-width: 640px;
                    margin: 1.75rem auto;
                }

                &.modal-dialog-centered {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    min-height: calc(100% - (.5rem * 2));

                    @media (min-width: 576px) {
                        min-height: calc(100% - (1.75rem * 2));
                    }
                }

                .modal-content {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    width: 100%;
                    pointer-events: auto;
                    background-color: #000000;
                    background-clip: padding-box;
                    border: 1px solid rgba(255, 255, 255, .2);
                    border-radius: .3rem;
                    outline: 0;
                    box-shadow: -1px -1px 18px rgba(251, 247, 165, 0.3);

                    .modal-body {
                        padding: 25px;

                        @media (max-width: 575px) {
                            padding: 15px;
                        }

                        h3 {
                            color: #ffc800;
                            font-weight: 600;
                            letter-spacing: 1px;
                            line-height: 1.6;
                            font-size: 20px;
                            text-transform: uppercase;

                            @media (max-width: 575px) {
                                font-size:14px;
                                line-height: 1.3;
                            }
                        }

                        p {
                            letter-spacing: 1px;
                            color: #ffffff;
                            font-weight: normal;
                            font-size: 16px;
                            font-family: 'MyriadProRegular', 'Lato', sans-serif;
                            line-height: 1.3;

                            &.para_heading {
                                color: #fffdd0;
                                font-size: 18px;
                            }

                            @media (max-width: 575px) {
                                font-size: 14px;
                            }
                        }

                        a {
                            color: #2baeff;

                            &:hover {
                                text-decoration: none;
                                color: #ffc800;
                            }
                        }

                        .btn {
                            background: #ffc800 !important;
                            border: none !important;
                            color: #000 !important;
                            -webkit-box-shadow: none !important;
                            box-shadow: none !important;
                            font-size: 18px;
                            font-weight: 600;
                            font-family: 'MyriadProRegular', 'Lato', sans-serif;
                            letter-spacing: 1px;

                            &:hover {
                                background: #87cefa !important;
                                color: #22292f !important;
                            }
                        }
                    }
                }
            }
        }

        .browser-list {
            padding: 0;
            margin: 0 0 20px;
            list-style: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            align-items: center;

            li {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
                text-align: center;
                padding: 0px 4px;

                a {
                    color: #ffd700;
                    letter-spacing: 1.3px;
                    font-size: 13px;

                    @media (max-width: 575px) {
                        font-size: 9px;
                    }
                }

                span {
                    display: block;
                    margin-top: 3px;
                    font-size: 13px;
                    color: #f2f2f2;
                    font-family: 'MyriadProRegular', 'Lato', sans-serif;
                    letter-spacing: 1px;

                    @media (max-width: 575px) {
                        font-size: 11px;
                    }
                }

                img {
                    max-width: 60px;
                    display: block;
                    margin: 0 auto 15px;

                    @media (max-width: 575px) {
                        max-width: 40px;
                    }
                }
            }
        }
    }
}
</style>